import { default as _91slug_9361hjumaiUzMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/blog/[slug].vue?macro=true";
import { default as index7OQmClBZ8YMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/blog/index.vue?macro=true";
import { default as indexpO9XbOZJNyMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/index.vue?macro=true";
import { default as registerRKwQoIQnNpMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/register.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_9361hjumaiUzMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: index7OQmClBZ8YMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexpO9XbOZJNyMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerRKwQoIQnNpMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/register.vue").then(m => m.default || m)
  }
]